var host_url = process.env.VUE_APP_HOST_URL;
import router from "../../router";
const state = {
    whitePage: true,
    user: {},
    step: 1,
    bots:[],
    bot: {},
    loginCookie: '',
};

const getters = {
    whitePage: (state) => state.whitePage,
    user: (state) => state.user,
    step: (state) => state.step,
    bots: (state) => state.bots,
    bot: (state) => state.bot,
    loginCookie: (state) => state.loginCookie
};

const actions = {
    setCookie({},ck) {
        let d = new Date()
        d.setTime(d.getTime()+(ck.exHours)*60*60*1000)
        let expires = `expires=${d.toGMTString()}`
        document.cookie = `${ck.cname}=${ck.cvalue};${expires}}`
    },
    getCookie({commit},cname) {
        let name = `${cname}=`
        let ca = document.cookie.split(';')
        let value = ''
        ca.forEach(el=>{
            el = el.trim()
            if(el.indexOf(name) == 0){
                value = el.substring(name.length,el.length)
            }
        })
        // return value
        commit('setLoginCookie', value)
    },
    async getLoginUrl({commit}) {
        try {
            let resp = await axios.get(host_url + "/auth3/line/login?format=json");
            console.log(resp.data.url);
            commit('setIsloading', false)
            window.location.href = resp.data.url;
        } catch (error) {
            console.log(error);
            throw new Error('Login Fail')
        }
    },
    async checkLogin({commit, dispatch, state},isLogin) {
        try {
            // // //todo : cookie
            // //沒登入 => push login page
            let urlObj = new URL(location.href)
            let params = urlObj.searchParams;
            let code = params.get('code')
            let userData = JSON.parse(localStorage.getItem('userData'))
            let activeBot = JSON.parse(localStorage.getItem('activeBot'))
            let msg = params.get('msg')
            //login cookie check
            dispatch('getCookie','lymn_login')
            console.log('loginCookie',state.loginCookie);
            let isLogin = state.loginCookie
            //cookie expired or first login
            if(!isLogin && !code){
                console.log('to login page');
                if(router.history.current.path == '/login') return
                router.push('/login').catch(err => { console.log(err) })
                return
            }
            //has login cookie: direct next
            else if(isLogin){
                dispatch('getBotList',msg)
                commit('setUser', userData.user)
            }
            //重新登入後返回：set cookie & user LS, then next
            else if(code){
                commit('setIsloading', true)
                console.log('have code');
                //1 set cookie
                dispatch('setCookie',{cname:'lymn_login',cvalue:'1', exHours:4})
                dispatch('getCookie','lymn_login')
                //2 授權
                let payload = {"code": code}
                console.log(payload)
                let resp = await axios.post(host_url + "/auth3/line/callback", payload);
                console.log('login data',resp.data);

                //3 set user data in LocalStorage
                if(userData){
                    if(resp.data.user.username !== userData.user.username){
                        dispatch('clearStorage')
                    }else if(activeBot){
                        dispatch('chooseBot',activeBot.id)
                    }
                }
                localStorage.setItem('userData', JSON.stringify(resp.data))
                commit('setUser', resp.data.user)

                //4 get bot list
                dispatch('getBotList')
            }
        }
        catch(error) {
            alert('登入失敗，請再試一次');
            console.log(error);
            throw new Error('Login Fail')
        }  
    },
    async chooseBot({commit},botId) {
        console.log('choseBot',botId);
        try {
            fbq('track', 'StartTrial');
        } catch (error) {
            console.log(error)
        }
  
        try {
            let resp = await axios.post(host_url + `/module/bots/${botId}/choose/`);
            console.log(resp.data);
            return resp.data
        } catch (error) {
            commit('setIsloading', false)
            console.log(error);
            throw new Error('Get bots Fail')
        }
    },
    async getBotList({commit,state},msg) {
        try {
            let resp = await axios.get(host_url + "/module/bots/")
            console.log(resp.data)
            let data = resp.data
            data.forEach(el=>{
                if(new Date() > new Date(el.end_at) || !el.end_at) el.isExpired = true
                else return false
            })
            console.log('data',data);
            commit('setBots',data)
            console.log('getBots',state.bots)
            // console.log(router.currentRoute);
            let path = router.currentRoute.path
            if(state.bots.length != 0 && msg){
                commit('setWhitePage', false)
                console.log('已綁定完帳號 turn to step 3 選擇官方帳號')
                commit('setStep', 3)
            }else if(state.bots.length == 0){
                commit('setWhitePage', false)
                console.log('已登入但尚未綁定line帳號')
            }
            else if(state.bots.length !=0){
                if(path == '/' || path == '/login') router.push('/invit').catch(err => { console.log(err) })
                let activeBot = JSON.parse(localStorage.getItem('activeBot'))
                if(new Date()>new Date(activeBot.end_at)){
                    console.log('帳號已過期');
                    commit('setShowBots',true)
                    localStorage.setItem('showBots',true)
                    return
                }
                console.log('已登入且已綁定帳號 && 不綁頁面');
            }
            // else if(state.bots.length !=0 && path != '/invit' && path != '/'){
            //     console.log('已登入且已綁定帳號 && 不綁頁面');
            //     return
            // }else if(state.bots.length !=0){
            //     console.log('已登入且已綁定帳號');
            //     if(path == '/invit') return
            //     router.push('/invit').catch(err => { console.log(err) })
            // }
            
            commit('setIsloading', false)


            // state.bots.length == 0
            // console.log('已登入但尚未綁定line帳號')
            // commit('setWhitePage', false)  
            // // console.log('已綁定完帳號 turn to step 3')
            // if(state.bots.length != 0 && msg){
                // commit('setWhitePage', false)
                // console.log('已綁定完帳號 turn to step 3 選擇官方帳號');
                // commit('setStep', 3)
            // }
            // console.log('已登入且已綁定帳號');
            // if(router.history.current.path == '/invit') return
            // router.push('/invit')

            
        } catch (error) {
            console.log(error);
            throw new Error('Get bots Fail')
        }
    },
    async getModuleAuthUrl() {
        try {
            let resp = await axios.get(host_url + "/module/auth?format=json");
            console.log(resp.data.url);
            window.location.href = resp.data.url;
        } catch (error) {
            console.log(error);
            throw new Error('Login Fail')
        }
    },
    async detachOA({commit}) {
        try {
            let resp = await axios.post(host_url + "/module/detach/");
            console.log(resp.data);
        } catch (error) {
            commit('setIsloading', false)
            console.log(error);
            throw new Error('detach Fail')
        }
    },
    async logout({dispatch,commit}) {
        try {
            commit('setIsloading', true)
            let resp = await axios.post(host_url + "/auth3/logout");
            console.log(resp);
            dispatch('clearCookie')
            dispatch('getCookie','lymn_login')
            commit('setIsloading', false)
            router.push('/login')
        } catch (error) {
            console.log(error);
        }
    },
    clearCookie({commit,dispatch}){
        dispatch('setCookie',{cname:'lymn_login',cvalue:'0', exHours:0})
        commit('setUser', {})
    },
    clearStorage(){
        localStorage.removeItem('userData')
        localStorage.removeItem('showBots')
        localStorage.removeItem('invBread')
        localStorage.removeItem('activeBot')
    }
};

const mutations = {
    setWhitePage: (state, whitePage) => (state.whitePage = whitePage),
    setUser: (state, user) => (state.user = user),
    setStep: (state, step) => (state.step = step),
    setBots: (state, bots) => (state.bots = bots),
    setBot: (state, bot) => (state.bot = bot),
    setLoginCookie: (state, loginCookie) => (state.loginCookie = loginCookie),
};

export default{
    state,
    getters,
    actions,
    mutations,
}