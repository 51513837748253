<template>
    <v-row justify="center">
        <v-dialog
        v-model="dialog"
        persistent
        max-width="450"
        >
        <v-card>
            <v-card-title class="text-h5">
            提示
            </v-card-title>
            <v-card-text>
                <div v-html="alertMsg.msg"></div>
                <div v-show="addAcc" class="text-center">
                    <br/>
                    <a href="https://line.me/R/ti/p/@585cvzsv" target="_blank">加入荔枝官方帳號</a>
                    <qrcode-vue class="mt-4" value="https://line.me/R/ti/p/@585cvzsv" size="100" level="H"></qrcode-vue>
                </div>
            </v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="green darken-1"
                text
                @click="setAlertDialog(false)"
                v-show="!addAcc"
            >
                取消
            </v-btn>
            <v-btn
                color="green darken-1"
                text
                @click="confirm"
            >
                確定
            </v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
// import QrcodeVue from 'qrcode.vue';
import { mapGetters,mapMutations,mapActions } from 'vuex'
export default {
    components: { QrcodeVue },
    data(){
        return{
            promise: '',
            resolve: '',
            reject: '',
            addAcc: false,
        }
    },
    computed:{
        ...mapGetters(['deleteItem','alertMsg']),
        dialog(){
            return this.$store.getters.alertDialog
        }
    },
    watch:{
        alertMsg(val){
            console.log('alertMsg',val);
            if(this.alertMsg.mode == 'lackLineP'){
                console.log('in lackLineP');
                this.addAcc = true
                console.log('addAcc',this.addAcc);
            }
        }
    },
    methods:{
        ...mapActions(['deleteInvitation']),
        ...mapMutations(['setAlertDialog','setAgreement','setCancelAns']),
        confirm(){
            if(this.alertMsg.mode == 'delete'){
                this.$store.commit('setIsloading',true)
                console.log('in delete');
                this.deleteInvitation(this.deleteItem.id)
            }
            if(this.alertMsg.mode == 'leave'){
                console.log('in leave');
                this.setCancelAns(true)
            }
            if(this.alertMsg.mode == 'lackLineP'){
                console.log('in lackLineP');
                this.addAcc = false
            }
            this.setAlertDialog(false)
        }
    }
}
</script>
<style scoped>

</style>