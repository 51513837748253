<template>
<div>
    <v-row style="min-height:200px; max-width:1500px;" class="mb-10 ma-auto">
        <v-col v-for="item in bots" :key="item.id" cols="12" sm="6" md="3" xl="2" :class="{'hidden':!landDisplay(item)}">
            <v-card v-if="landDisplay(item)" @click="choose(item)" class="mb-2 mx-auto accCard" :class="[{'active': item.active},{'expired': item.isExpired}]" height="260" max-width="320" min-width="220">
                <div class="text-right pr-3 pt-2" style="height:30px">
                    <v-menu v-if="!msg" offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on">mdi-dots-vertical</v-icon>
                    </template>
                    <v-list>
                        <v-list-item @click="deleteAcc(item.id)">
                            <v-list-item-title>解除綁定</v-list-item-title>
                        </v-list-item>
                    </v-list>
                    </v-menu>
                </div>
                
                <div class="imgWrap mt-5">
                    <img
                    :class="{'expired':item.isExpired}"
                    :src="item.picture_url"
                    /> 
                    
                </div>

                <h3>{{ item.display_name }}</h3>
                <p class="mt-3 mb-0">Basic ID</p>
                <p>{{ item.basic_id }}</p>
            </v-card>
        </v-col>
        <v-col v-if="!msg" cols="12" sm="6" md="3" xl="2">
            <v-card @click="dialog=true" class="mb-2 mx-auto accCard accCard_add d-flex justify-center" height="260" max-width="320" min-width="220">
                <v-icon size="50">mdi-plus</v-icon>
            </v-card>
        </v-col>
    </v-row>  
    <v-dialog
        v-model="dialog"
        persistent
        max-width="800"
        >
        <v-card class="px-2 px-md-12 py-10 text-center">
            <v-card-title class="text-h6 px-md-10 justify-center">
            新增綁定LINE官方帳號
            </v-card-title>
            <v-divider class="mb-8"></v-divider>
            <terms :agree="agree" @toggleCheck="agree=!agree"></terms>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                @click="getModuleAuthUrl"
                :disabled="agree==false"
            >
                繼續
            </v-btn>
            <v-btn
                color="green darken-1"
                text
                @click="dialog=false"
            >
                取消
            </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</div>
</template>
<script>
import { mapGetters,mapActions } from 'vuex'
import Terms from './Terms.vue'
export default {
    components:{Terms},
    props:{
        landing:{
            type: Boolean,
            default: false 
        }
    },
    data(){
        return{
            dialog: false,
            agree: false
        }
    },
    computed:{
        ...mapGetters(['bots','bot']),
        msg(){
            let msg = new URL(location.href).searchParams.get('msg')
            return msg
        },

    },
    methods:{
        ...mapActions(['getModuleAuthUrl','detachOA','chooseBot','getBotList']),
        choose(bot){
            if(bot.isExpired) {
                this.$store.commit('setAlertDialog',true)
                this.$store.commit('setAlertMsg',{mode:'lackLineP',msg:'親愛的用戶，此帳號使用期限已結束，如需繼續使用，請洽荔枝智慧客服詢問付費方案。'})
                return
            }
            if(bot.active == undefined){
                this.bots.forEach(el=>{
                this.$set(el,'active',false)
                })
            }else{
                this.bots.forEach(el=>{
                el.active = false
                })
            }
            bot.active = true
            this.$store.commit('setBot',bot)
            console.log(this.bot);
            this.$store.commit('setShowBots',false)
        },
        async deleteAcc(id){
            this.$store.commit('setIsloading', true)
            await this.chooseBot(id)
            await this.detachOA()
            await this.getBotList()
            this.$store.commit('setIsloading', false)
        },
        landDisplay(item){
            if(!this.landing) return true
            else if(!item.isExpired) return true
            else return false
        }
    }
}
</script>
<style lang="scss" scoped>
    $primary: #74b36b;
    $secondary: #aadba3;
    .hidden{
        display: none;
    }
    .accCard{
        text-align: center;
        border: 1px solid #ddd !important;
        transition: .2s;
        .imgWrap{
            height:80px;
            width: 80px;
            margin: 0 auto;
            border-radius: 20px !important;
            overflow: hidden;
            background:#ccc;
            margin-bottom: 24px;
            img{
                border-radius:20px;
                width:100%;
                &.expired{
                    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
                    filter: grayscale(100%);
                }
            }
        }
        h3{
            white-space: nowrap;
            width: 70%;
            margin: 0 auto;
            overflow: hidden;
            text-overflow : ellipsis;
        }
        p{
            color: #777;
        }
        &_add{
            border: 1px solid $secondary !important;
            .v-icon{
                color: $secondary;
            }
            &:hover .v-icon{
                color: $primary;
            }
        }
    }
    .accCard.active{
        box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 10px 0px !important;
        color: $primary !important;
        border: 1px solid $secondary !important;
    }
    .accCard:hover{
        box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 10px 0px !important;
        color: $primary !important;
    }
    .accCard.expired:hover{
        cursor: default;
        color: inherit !important;
        box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%) !important;
    }

</style>